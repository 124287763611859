<template>
	<h1 autofocus>
		Serviceberry Catering
	</h1>
	<Services />
	<div
		class="elfsight-app-a77fe92a-cd1d-4a61-af7d-829e48dfdca7"
	></div>
</template>

<script>
import Services from '@/components/Services';
export default {
	name: 'Home',
	components: { Services },
};
</script>

<style lang="scss">
h1 {
	font-size: 5em;
}
.layout {
	margin-top: 2em;
}
.text {
	margin: 0;
	flex: 1 0 80%;
}
@media screen and (max-width: 1024px) {
	h1 {
		margin-top: 1em;
		font-size: 3em;
	}
}
@media screen and (max-width: 768px) {
	h1 {
		margin-top: 2em;
		font-size: 3em;
	}
}
@media screen and (max-width: 480px) {
	h1 {
		margin-top: 0.5em;
		font-size: 2em;
	}
}
</style>
