<template>
	<div class="service-container">
		<h5>
			We are a small Chef-owned caterer with a focus on local,
			seasonal menu creation. Founded in the beautiful
			foothills of Fort Collins, CO. We source from local farms
			and fisherman as often as possible. Serviceberry can
			accomodate many events and sizes, please fill out our

			<router-link :to="{ name: 'Contact' }"
				>Contact Form</router-link
			>
			and tell us about your event!
		</h5>
		<div class="service-card">
			<div class="event covid">
				<h4>Covid-19 Policy</h4>
				<p>
					Our team wants to keep you and your loved ones as safe as possilbe. We have modified our styles of service to provide masks, sanitizer and practice safe social distancing. Concerned about your next event? Talk to us about individually boxed, plated, and other safe ways to feed your guest. 
				</p>
			</div>
			<div class="event">
				<h2>Weddings</h2>
				<h6>
					Outdoor, indoor, micro, or large. We can customize your
					menu for your special day.
				</h6>
				<img
					src="@/assets/photos/outdoor-wedding.jpg"
					alt="Lovely Outdoor Weddings, Micro Weddings"
					title="Lovely Outdoor Weddings, Micro Weddings"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Private Chef</h2>
				<h6>
					Extended ski trips in in Aspen to anniversary dinners
					in Loveland, our team will make amazing meals based on
					your dietary restrictions and preferences.
				</h6>
				<img
					src="@/assets/photos/cannoli.jpg"
					alt="Catered Birthday Party"
					title="Catered Birthday Party"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Drop off: Private or Corporate</h2>
				<h6>
					We can set you up with packaged meals for boxed
					lunches, buffet, and corporate lunches!
				</h6>
				<img
					src="@/assets/photos/meal-prep-3.jpg"
					alt="Corporate Lunches, Private meal prep, boxed lunches"
					title="Corporate Lunches, Private meal prep, boxed lunches"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Meal Prep</h2>
				<h6>
					Going Keto, Paleo or Vegan? Cramming for finals or just
					too busy to put together some healthy meals? Let us
					help. Contact us for our seasonal meal prep items and
					stop worrying about dinner!
				</h6>
				<img
					src="@/assets/photos/meal-prep.jpg"
					alt="Corporate Lunches, Private meal prep, boxed lunches"
					title="Corporate Lunches, Private meal prep, boxed lunches"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Craft Services-</h2>
				<h6>
					Filming in Colorado? We provide craft services for your
					crew. Ask about our low glycemic menu to keep everyone
					energized during long shoots.
				</h6>
				<img
					src="@/assets/photos/pumpkin-pie.jpg"
					alt="We accomodate Holiday parties of all sizes"
					title="We accomodate Holiday parties of all sizes"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Custom Events</h2>
				<h6>
					Whether you are planning a small brunch, campaign,
					fundraiser, or grand opening. We can provide you
					amazing choices to make your event truly special.
				</h6>
				<img
					src="@/assets/photos/canapes.jpg"
					alt="Stationary Appetizers at your special event"
					title="Stationary Appetizers at your special event"
					height="300"
					loading="lazy"
				/>
			</div>
			<div class="event">
				<h2>Styles of Services</h2>
				<h6>
					Feel comfort with our ability to style your party with
					several service options to fit your budget.
				</h6>
				<ul>
					<li>Small appetizers - passed or buffet</li>
					<li>Buffet for all occasions</li>
					<li>Family style meals</li>
					<li>Plated multi-course events</li>
					<li>Boxed lunches or drop-off service</li>
				</ul>
				<img
					src="@/assets/photos/charcuterie.jpg"
					alt="Lovely Outdoor Weddings"
					title="Lovely Outdoor Weddings"
					height="300"
					loading="lazy"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Services',
};
</script>

<style lang="scss">
.service-container {
	width: 90%;
	margin-top: 2em;

	a {
		color: rgba(0, 0, 0, 1);
		cursor: pointer;
		font-style: italic;
	}

	.service-card {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
		margin: 2em 0;
		font-weight: bold;

		.event {
			background-color: rgba(190, 190, 190, 0.26);
			flex: 0 0 30%;
			align-self: stretch;
			margin: 0.5%;
			padding: 2%;
			border: 2px solid rgba(0, 0, 0, 0.4);
			border-radius: 10px;

			ul {
				list-style-type: none;
			}

			h2,
			h6 {
				font-weight: bold;
				text-align: center;
				width: 80%;
			}

			img {
				margin-top: 4%;
				max-height: 300px;
				max-width: 80%;
				border-radius: 20px;
				object-fit: cover;
			}

			&.covid{
				flex-basis: 100%;
				color: rgba(255,0,0,1);
				p {
					font-weight: normal;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		.event {
			img {
				height: 300px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.service-card {
			.event {
				flex-basis: 40%;
				img {
					height: 300px;
				}
			}
		}
	}

	@media screen and (max-width:480px){
			.service-card {
			.event {
				flex-basis: 100%;
				img {
					height: 300px;
				}
			}
		}
	}
}
</style>
