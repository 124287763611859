<template>
	<h2>Complete all fields</h2>
	<form
		action="https://api.airtable.com/v0/appg4xJvDB5W2nTa4/Inquiries?api_key=keykjl1ZxJZeGqJcK"
		method="POST"
		class="contact-form"
	>
		<div class="inputs">
			<!-- First Name -->
			<label for="firstName">First Name:</label>
			<input
				type="text"
				v-model="firstName"
				name="firstName"
				required
				autofocus
			/>
		</div>
		<!-- Last Name -->
		<div class="inputs">
			<label for="lastName">Last Name:</label>
			<input
				required
				type="text"
				v-model="lastName"
				name="lastName"
			/>
		</div>
		<!-- Phone -->
		<div class="inputs">
			<label for="phone">Phone Number:</label>
			<input
				type="tel"
				v-model="phone"
				name="phone"
				placeholder="xxx-xxx-xxxx"
				required
			/>
		</div>
		<!-- Email -->
		<div class="inputs">
			<label for="email">Email:</label>
			<input type="email" v-model="email" required />
		</div>
		<!-- Date -->
		<div class="inputs">
			<label for="date">Date of Event:</label>
			<input type="date" v-model="date" required />
		</div>
		<!-- Input type of event -->
		<div class="event-type">
			<label for="eventType" class="title"
				>What type of event are you hosting? (Select
				One):</label
			>
			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Wedding"
					id="wedding"
				/>
				<label for="wedding">Wedding</label>
			</div>

			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Birthday"
					id="birthday"
				/>
				<label for="birthday">Birthday</label>
			</div>

			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Corporate"
					id="corporate"
				/>
				<label for="corporate">Corporate</label>
			</div>

			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Holiday"
					id="holiday"
				/>
				<label for="holiday">Holiday</label>
			</div>

			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Drop Off"
					id="dropOff"
				/>
				<label for="dropOff">Drop Off</label>
			</div>

			<div class="each-event-type">
				<input
					type="checkbox"
					v-model="eventType"
					value="Other"
					id="other"
				/>
				<label for="other">Other</label>
			</div>
		</div>

		<!-- Service Style Inputs -->
		<div class="event-style">
			<label for="eventStyle" class="title"
				>What style of service would you like for your event?
				(Select One):</label
			>
			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Stationary appetizers"
					id="stationaryApps"
				/>
				<label for="stationaryApps"
					>Stationary Appetizers</label
				>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Passed appetizers"
					id="passedApps"
				/>
				<label for="passedApps">Passed Appetizers</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Buffet Dinner"
					id="buffet"
				/>
				<label for="buffet">Buffet</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Family Style Dinner"
					id="familystyle"
				/>
				<label for="familystyle">Family Style</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Plated Dinner"
					id="platedDinner"
				/>
				<label for="platedDinner">Plated Dinner</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Drop Off"
					id="dropOff"
				/>
				<label for="dropOff"> Drop-Off</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Other"
					id="other"
				/>
				<label for="other"> Other</label>
			</div>

			<div class="event">
				<input
					type="checkbox"
					v-model="eventStyle"
					value="Tell me more!"
					id="unSure"
				/>
				<label for="unSure"> Tell me more!</label>
			</div>

			<!-- Staff needed -->
			<div class="event-staff">
				<label class="title" for="eventStaff"
					>Will you need additional services/staff? (Select all
					that apply)</label
				>
				<div class="staff">
					<input
						type="checkbox"
						v-model="staff"
						value="Servers"
						id="servers"
					/>
					<label for="servers">Servers/Waitstaff</label>
				</div>

				<div class="staff">
					<input
						type="checkbox"
						v-model="staff"
						value="Bartenders"
						id="bartenders"
					/>
					<label for="bartenders">Bartenders</label>
				</div>

				<div class="staff">
					<input
						type="checkbox"
						v-model="staff"
						value="Photographer"
						id="photographer"
					/>
					<label for="photographer">Photographer</label>
				</div>

				<div class="staff">
					<input
						type="checkbox"
						v-model="staff"
						value="VideoGrapher"
						id="videographer"
					/>
					<label for="videographer">VideoGrapher</label>
				</div>

				<div class="staff">
					<input
						type="checkbox"
						v-model="staff"
						value="Event Planner"
						id="eventPlanner"
					/>
					<label for="eventPlanner">Event Planner</label>
				</div>
			</div>

			<!-- Guest Count -->
			<div class="inputs">
				<label for="guestCount"
					>What is your expected guest count?</label
				>
				<input type="text" v-model="guestCount" required />
			</div>

			<!-- Venue -->
			<div class="inputs">
				<label for="venueCheck">Do you have a venue?</label>
				<input
					type="text"
					v-model="venueCheck"
					placeholder="Please type venue name"
					required
				/>
			</div>

			<!-- Budget -->
			<div class="inputs">
				<label for="budget">Budget: </label>
				<input type="text" v-model="budget" required />
			</div>

			<!-- Vision for event -->
			<div class="inputs vision">
				<label for="vision"
					>Do you already have a vision? Let us know and we will
					be happy to help!</label
				>
				<div>
					<textarea
						name="vision"
						id="vision"
						v-model="vision"
						placeholder="Please describe here..."
					></textarea>
				</div>
			</div>
		</div>
		<div>
			<h3>{{ error }}</h3>
		</div>
		<div>
			<input
				type="submit"
				value="Submit"
				class="submit"
				@click.prevent="handleSubmit"
			/>
		</div>
    <div v-if="thank">{{ thank.value }}</div>
	</form>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
	name: 'Form',
	setup() {
		const router = useRouter();
		const firstName = ref('');
		const lastName = ref('');
		const phone = ref('');
		const email = ref('');
		const date = ref('');
		const eventType = ref([]);
		const guestCount = ref();
		const venueCheck = ref('');
		const eventStyle = ref([]);
		const staff = ref([]);
		const budget = ref('');
		const vision = ref('');
		const error = ref('');
		const thank = ref('');

		const handleSubmit = async function post(e) {
			if (!firstName.value) {
				error.value = 'Please fill all fields';
				console.log(error);
			} else {
				const response = await fetch(
					`https://api.airtable.com/v0/appg4xJvDB5W2nTa4/Inquiries?api_key=keykjl1ZxJZeGqJcK`,
					{
						method: 'POST',
						headers: {
							'Content-type': 'application/json',
							Authorization: 'Bearer keykjl1ZxJZeGqJcK',
						},
						body: JSON.stringify({
							fields: {
								'Last Name': lastName.value,
								'First name': firstName.value,
								Phone: phone.value,
								'Do you have a venue?': venueCheck.value,
								Date: date.value,
								Event: eventType.value,
								'Number of Guests': guestCount.value,
								'Food Styles': eventStyle.value,
								'Food Budget': budget.value,
								'Additional Services': staff.value,
								Email: email.value,
								'A space for you. Already have a vision? Have a few questions? Let us know and we will be happy to help!':
									vision.value,
							},
							typecast: true,
						}),
					}
				)
					// console.log(eventType.value);
					// const resData = await response.json();
					// console.log(resData);
					.then((data) => console.log(data))
					.catch((error) => {
						error.value = err.message;
					});

				setTimeout(() => {
					thank =
						'Thank you for your inquiry! We will be in contact in two business days.';
				}, 2000);
			}

			router.push({ name: 'Home' });
		};

		return {
			firstName,
			lastName,
			phone,
			email,
			date,
			eventType,
			guestCount,
			venueCheck,
			eventStyle,
			staff,
			budget,
			vision,
			handleSubmit,
			error,
			thank,
		};
	},
};
</script>

<style lang="scss">
.contact-form {
	margin-top: 5%;
	font-size: 20px;
	.inputs {
		width: 100%;
		margin: 1%;
		text-align: center;
		label {
			width: 100%;
			margin-bottom: 0.5%;
			font-size: 24px;
		}
		input {
			border: 2px solid rgba(0, 0, 0, 0.4);
			border-radius: 10px;
			height: 3rem;
			width: 60%;
			text-align: center;
			outline: none;
		}
		::placeholder {
			font-weight: bold;
		}
	}
	.event-type,
	.event-style,
	.event-staff {
		margin: 2% 0 4% 0;
		.title {
			font-size: 24px;
			margin-bottom: 2.5%;
		}
		label {
			margin-left: 1%;
		}
		.each-event-type,
		.event,
		.staff {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: center;
			width: 45%;
			margin-bottom: 0.5%;
			border-bottom: 1px dashed rgba(0, 0, 0, 0.4);

			label,
			input {
				flex: 1;
				margin: 1%;
				padding: 1%;
				cursor: pointer;
			}
		}
	}

	textarea {
		height: 6rem;
		width: 60%;
		outline: none;
	}
	.submit {
		width: 20%;
		font-weight: bold;
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		box-shadow: 4px 3px 4px #888888;
		transition: all 150ms linear;
		cursor: pointer;
		outline: none;

		&:hover {
			transform: scale(1.05);
		}

		&:active {
			margin: -3px -3px;
			box-shadow: 4px 4px 3px #888888;
		}
	}
}
</style>
