<template>
	<div
		id="carouselExampleSlidesOnly"
		class="carousel slide carousel-fade"
		data-bs-ride="carousel"
		loading="lazy"
	>
		<div class="carousel-inner">
			<div
				class="carousel-item active"
				data-bs-interval="3000"
			>
				<img
					src="@/assets/photos/fresh-pasta.jpg"
					class="d-block w-100"
					alt="Fresh Pasta"
				/>
				<div class="carousel-caption d-none d-md-block">
					<h5>
						Fresh Pasta
					</h5>
				</div>
			</div>
			<div class="carousel-item" data-bs-interval="3000">
				<img
					src="@/assets/photos/fig-granola.jpg"
					class="d-block w-100"
					alt="Fig Granola"
				/>
				<div class="carousel-caption d-none d-md-block">
					<h5>Fig Granola</h5>
				</div>
			</div>
			<div class="carousel-item" data-bs-interval="3000">
				<img
					src="@/assets/photos/white-chocolate-cherries.jpg"
					class="d-block w-100"
					alt="White Chocolate covered Cherries"
					title="White Chocolate covered Cherries"
				/>
				<div class="carousel-caption d-none d-md-block">
					<h5>White chocolate covered cherries</h5>
				</div>
			</div>
			<div class="carousel-item" data-bs-interval="3000">
				<img
					src="@/assets/photos/truffled-egg.jpeg"
					class="d-block w-100"
					alt="Coddled Truffle Egg"
					title="Coddled Truffle Egg"
				/>
				<div class="carousel-caption d-none d-md-block">
					<h5>Coddled Truffle Egg</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Slider',
	components: {},
};
</script>

<style lang="scss">
.carousel {
	margin: 0;
	width: 100%;
	img {
		object-fit: cover;
		max-height: 515px;
	}
	h5 {
		background-color: rgba(31, 29, 29, 0.7);
		width: max-content;
		margin: auto;
		padding: 5px 15px;
		border-radius: 15px;
	}
}

@media screen and (max-width: 1900px) {
	.carousel {
		img {
			height: 435px;
		}
	}
}
@media screen and (max-width: 1024px) {
	.carousel {
		img {
			height: 330px;
		}
	}
}
@media screen and (max-width: 768px) {
	.carousel {
		img {
			height: 280px;
		}
	}
}
@media screen and (max-width: 480px) {
	.carousel {
		img {
			height: 200px;
		}
	}
}
</style>
