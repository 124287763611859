<template>
	<div class="mobile">
		<div class="mobile-logo">
			<img
				src="@/assets/photos/logo.png"
				alt="Serviceberry Catering Logo"
				class="main-img"
				height="100"
			/>
		</div>
		<div class="sb-info">
			<h1>Serviceberry Catering</h1>
			<p>Fort Collins, CO</p>
			<a href="tel:9703291708">970.329.1708</a>
		</div>
		<div class="mobile-bars" @click="showModal = !showModal">
			<i v-show="!showModal" class="fas fa-bars fa-2x"></i>
			<i v-show="showModal" class="fas fa-times fa-2x"></i>
		</div>
		<div v-if="showModal" class="mobile-modal">
			<nav class="mobile-nav">
				<router-link :to="{ name: 'Home' }">Home</router-link>
				<router-link :to="{ name: 'About' }"
					>About Us</router-link
				>
				<router-link :to="{ name: 'Contact' }"
					>Contact</router-link
				>
				<a
					href="https://www.instagram.com/serviceberrycatering/"
					target="_blank"
				>
					<i class="fab fa-instagram fa-md"></i>
					Instagram
				</a>
				<a
					href="https://www.facebook.com/Serviceberrycatering/"
					target="_blank"
				>
					<i class="fab fa-facebook fa-md"></i>
					Facebook
				</a>
			</nav>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
export default {
	setup() {
		const showModal = ref(false);
		const isActive = ref(true);

		return { showModal, isActive };
	},
};
</script>

<style lang="scss">
@media screen and (min-width: 481px) {
	.mobile {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		padding: 0;

		.sb-info {
			flex: 1 0 50%;
			h1,
			p,
			a {
				margin: 0;
				color: rgba(0, 0, 0, 0.7);
			}
			h1 {
				font-weight: bold;
				font-size: 1em;
			}
			p {
				font-size: 0.8em;
			}
			a {
				text-decoration: none;
				font-size: 0.8em;
			}
		}
		.mobile-logo {
			flex: 1 1 20%;
			height: 90px;
		}
		.mobile-bars {
			flex: 1 1 20%;
			.fa-bars {
				color: rgba(0, 0, 0, 0.85);
			}
		}
		.mobile-modal {
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			width: 70%;
			z-index: 55;
			animation: fadeIn 1500ms;

			.mobile-nav {
				display: block;
				position: absolute;
				width: 100%;

				a {
					display: block;
					background-color: rgba(255, 255, 255, .9);
					color: rgba(0, 0, 0, 0.8);
					margin: 0;
					padding: 5px;
					font-size: 1.5rem;
					font-style: italic;
					text-decoration: none;
					border-right: none;
					cursor: pointer;

					&:hover {
						transform: none;
						border-right: none;
					}
					i {
						color: rgba(0, 0, 0, 1);
					}
				}
			}
		}

		@keyframes fadeIn {
			0% {
				right: 0;
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				right: 100%;
				opacity: 1;
			}
		}
	}
}
</style>
