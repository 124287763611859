<template>
	<nav class="main-nav">
		<div class="logo">
			<img
				src="@/assets/photos/logo.png"
				alt="ServiceBerry Catering Logo"
				class="main-img"
			/>
		</div>
		<router-link :to="{ name: 'Home' }">Home</router-link>
		<router-link :to="{ name: 'About' }"
			>About Us</router-link
		>
		<router-link :to="{ name: 'Contact' }"
			>Contact</router-link
		>
		<a
			href="https://www.instagram.com/serviceberrycatering/"
			target="_blank"
		>
			<i class="fab fa-instagram fa-md"></i>
			Instagram
		</a>
		<a
			href="https://www.facebook.com/Serviceberrycatering/"
			target="_blank"
		>
			<i class="fab fa-facebook fa-md"></i>
			Facebook
		</a>
	</nav>
</template>

<script>
export default {
	name: 'Navbar',
};
</script>

<style lang="scss">
nav {
	display: flex;
	flex-direction: column;
	width: 15vw;
	height: 100%;
	margin: 0 1%;

	.logo {
		margin: 0;
		img {
			max-width: 100%;
		}
	}

	a {
		margin: 0 0 10% 0;
		padding-right: 2%;
		color: rgba(0, 0, 0, 0.5);
		font-size: 1.5rem;
		font-style: italic;
		width: 100%;
		text-decoration: none;
		cursor: pointer;
		border-bottom: 1px solid rgba(0, 0, 0, 0.4);
		border-right: 1px solid rgba(0, 0, 0, 0.4);
		transition: all 150ms linear;

		&:hover {
			transform: scale(1.05);
			color: rgba(0, 0, 0, 1);
			border-bottom: 1px solid rgba(0, 0, 0, 1);
			border-right: 1px solid rgba(0, 0, 0, 1);
		}

		&:active {
			transform: scale(.9);
		}

		i {
			color: rgba(0, 0, 0, 1);

			&:hover {
				transform: scale(1.05);
				color: rgba(0, 0, 0, 1);
				border-bottom: 1px solid rgba(0, 0, 0, 1);
				border-right: 1px solid rgba(0, 0, 0, 1);
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	nav {
		a {
			font-size: 1em;
			margin-bottom: 14%;
		}
	}
}
@media screen and (max-width: 480px) {
	nav {
		display: none;
	}
}
</style>
