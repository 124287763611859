<template>
			<h1>
				About Us
			</h1>
	<div class="chefImg">
		<div class="eachChef">
			<img
				src="@/assets/photos/rachel.jpeg"
				title="Chef Rachel"
				alt="Chef Rachel"
				height="300"
				class="rachel"
			/>
			<h5>
				Chef-Owner <br />
				<strong> Rachel Hunt</strong>
			</h5>
		</div>
		<div class="eachChef">
			<img
				src="@/assets/photos/trent.jpeg"
				title="Chef Trent Bauer"
				alt="Chef Trent Bauer"
				height="300"
				class="trent"
			/>
			<h5>
				Chef-Owner <br />
				<strong> Trent Bauer</strong>
			</h5>
		</div>
	</div>
	<div class="about-text">
		<h3>
			Serviceberry Catering was created by Trent Bauer and
			Rachel Hunt as they contemplated how to highlight the
			many flavors of Northern Colorado. By showcasing our
			diverse seasonality, local bounty, and attention to
			detail, we are able to provide the fresh inventive cuisine
			that fills your belly and our community.
		</h3>
		<h1>What is a Serviceberry?</h1>
		<h3>
			An indigenous rose to North American, the serviceberry
			thrives in harsh conditions but still produces delicious
			fruit. Much like the serviceberry, we have created
			beautiful meals in fields and venues without the luxury
			of kitchens, building our own to make a memorable meal
			for you and your closest.
		</h3>
	</div>
</template>

<script>
export default {
	name: 'About'
};
</script>

<style lang="scss">

.chefImg {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	margin: 3em 3em;
	animation: fadeIn2 1s ease-out;
	img {
		border-radius: 50%;
	}
	h5 {
		margin-top: 1em;
	}
}
.about-text {
	width: 80%;
	margin: auto;
	h1,
	h3 {
		margin-top: 2em;
	}

	@keyframes fadeIn2 {
		0% {
			transform: translateX(-50%);
			opacity: 0;
		}
		100% {
			transform: translateX(0%);
			opacity: 1;
		}
	}
}
@media screen and (max-width: 1024px) and (min-width: 481px){
	.chefImg {
		img {
			height: 300px;
		}
	}
	.about-text {
		margin: auto;
	}
}

@media screen and (max-width: 480px) {
	.layout {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: center;

		.text {
			margin: auto;
			h1 {
				font-size: 3em;
			}
		}
	}
	.about {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 0.25em;
	}

	.chefImg {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.trent {
			margin-top: 3em;
		}

		img {
			border-radius: 50%;
		}
		h5 {
			width: 100%;
		}
	}

	.about-text {
		width: 100%;
		h1 {
			font-size: 3em;
		}
		h3 {
			margin: auto;
			font-size: 1.2em;
			width: 90%;
		}
	}
}
</style>
