<template>
	<h1>
		Contact Us
	</h1>
<Form />
</template>

<script>
import Form from '@/components/Form'
export default {
	name: 'Contact',
	components: { Form }
};
</script>

<style lang="scss">
</style>
