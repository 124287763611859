<template>
	<div class="footer">
		<div class="footer-contact">
			<h3>Serviceberry Catering</h3>
			<h3>Fort Collins, Colorado</h3>
			<p>
				<a href="tel: 9703291708">
					<i class="fas fa-mobile"></i>
					970.329.1708
				</a>
			</p>
		</div>
		<div class="footer-logo">
			<img
				src="@/assets/photos/logo.png"
				alt="ServiceBerry Catering Logo"
				class="main-img"
				height="300"
			/>
		</div>
		<div class="footer-social">
			<p>
				<a
					href="https://www.instagram.com/serviceberrycatering/"
					target="_blank"
				>
					<i class="fab fa-instagram-square fa-2x"></i>
					@serviceberrycatering
				</a>
			</p>
			<p>
				<a
					href="https://www.facebook.com/Serviceberrycatering/"
					target="_blank"
				>
					<i class="fab fa-facebook-square fa-2x"></i>
					Serviceberry Catering
				</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
};
</script>

<style lang="scss">
.footer {
	display: flex;
	background-color: rgba(190, 190, 190, 0.26);
	align-items: center;
	justify-content: space-between;
	max-height: 300px;
	margin-top: 5%;
	margin-bottom: 0;
	border-top: 2px solid rgba(0, 0, 0, 0.4);
	border-bottom: 2px solid rgba(0, 0, 0, 0.4);

	.footer-contact {
		h3,
		p {
			font-size: 1.1em;
			margin-bottom: 0;
			font-weight: bold;
		}

		a {
			font-size: 1.1rem;
			color: rgba(0, 0, 0, 0.8);
			text-decoration: none;
			cursor: pointer;
		}
	}
	.footer-logo {
		img {
			object-fit: cover;
			object-position: 0 20px;
			max-height: 300px;
		}
	}

	.footer-social {
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: rgba(0, 0, 0, 0.8);
			text-decoration: none;
			cursor: pointer;
			i {
				padding-right: 3%;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.footer {
		padding: 0px 1em;
		.footer-contact {
			h3,
			a {
				font-size: 0.7em;
			}
		}
		.footer-logo {
			img {
				height: 150px;
				object-position: 0 5px;
			}
		}
		.footer-social {
			a {
				font-size: 0.5em;
			}
			i {
				font-size: 1.7em;
			}
		}
	}
}
</style>
