<template>
	<MobileNavbar />
	<div class="header">
		<Navbar />
		<Slider />
	</div>
	<div class="body">
		<router-view />
	</div>
	<Footer />
</template>

<script>
import Navbar from '@/components/Navbar';
import MobileNavbar from '@/components/MobileNavbar';
import Slider from '@/components/Slider';
import Footer from '@/components/Footer';
export default {
	components: { Navbar, Slider, MobileNavbar, Footer },
};
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;

	.router-link-active {
		color: rgb(0, 0, 0);
	}
	.header {
		display: flex;
		align-items: start;
		justify-content: center;
	}

	.body {
		margin: 0;
		width: 100%;
	}

	@media screen and (max-width: 1024px) {
		.header {
			margin: 0;
			height: 300px;
		}
	}
	@media screen and (max-width: 768px) {
		.header {
			height: 280px;
		}
	}
	@media screen and (max-width: 768px) {
		.header {
			height: 200px;
		}
	}
}
</style>
