import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/main.css'
import Vue from 'vue'
import Meta from 'vue-meta'


const app = createApp(App).use(router).mount('#app')
Vue.use(Meta)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})